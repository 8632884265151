<template>
  <div
    v-loading="loading"
    element-loading-text="导入数据中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <Header @loginUp="loginUp"></Header>
    <el-container
      style="padding: 15px 15px 10px 15px; background: #f7faff"
      id="body"
      :style="{ height: elementHeight + 'px' }"
    >
      <el-aside class="aside">
        <div class="compy" v-if="$store.state.tenantId != 0">
          <img src="../../assets/images/home2.png" alt />
          <div>
            <p @click="clickBtn">{{ compnyName }}</p>
            <!-- <p @click="clickBtn">某某公司</p> -->
            <p>
              <span>所在部门：</span>
              {{ deptName }}
            </p>
          </div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <div class="compy" style="justify-content: center" v-else>
          <el-button
            icon="el-icon-plus"
            type="primary"
            @click="clickShowDialog(1)"
            >加入公司</el-button
          >
          <el-button
            icon="el-icon-share"
            type="warning"
            @click="clickShowDialog(2)"
            >创建团队</el-button
          >
        </div>
        <div class="user-info">
          <div class="user">
            <el-badge :is-dot="dot">
              <img :src="imageUrl" />
            </el-badge>
            <span>{{ user.userName }}</span>
          </div>
          <!-- <div class="position">
            <span>所在位置</span>
            <span>科技研发</span>
          </div>-->

          <div class="title">
            <img src="../../assets/images/home1.png" />我的任务
          </div>
          <div class="item1 item">
            <span>体系落地</span>
            <span>现场管理</span>
            <span>考核细则</span>
          </div>
          <div
            class="item2 item"
            style="border-bottom: 1px solid #c0c0c03a; padding-bottom: 10px"
          >
            <span>0</span>
            <span>0</span>
            <span>0</span>
          </div>

          <div class="title">
            <img src="../../assets/images/home1.png" />待处理项
          </div>
          <div class="item1 item">
            <span @click="toLearn">待学习-文件</span>
            <span @click="toLearn">待考核-文件</span>
            <span @click="toTheme">待学习-专题</span>
            <span @click="toTheme">待考核-专题</span>
          </div>
          <div class="item2 item">
            <span @click="toLearn">{{
              pendingForm.studyCount ? pendingForm.studyCount : 0
            }}</span>
            <span @click="toLearn">{{
              pendingForm.testCount ? pendingForm.testCount : 0
            }}</span>
            <span @click="toTheme">{{
              pendingForm.themeStudyCount ? pendingForm.themeStudyCount : 0
            }}</span>
            <span @click="toTheme">{{
              pendingForm.themeTestCount ? pendingForm.themeTestCount : 0
            }}</span>
          </div>
        </div>
        <div class="bulletin">
          <div class="title">
            <img src="../../assets/images/home3.png" />公告
            <i class="el-icon-arrow-right"></i>
          </div>
          <div class="info">
            <!-- <div>
              <p><span>●</span>干饭啦干饭啦...</p>
              <span>2022-10-12</span>
            </div> -->
          </div>
        </div>
      </el-aside>
      <el-main class="main">
        <div class="use">
          <p>应用中心</p>
          <div class="userItem">
            <div class="png">
              <div>
                <p>
                  体系委托审核<img
                    @click="openWindow"
                    src="../../assets/images/big6.png"
                    alt=""
                  />
                </p>
                <p>专享老用户更多优惠</p>
              </div>
            </div>
            <div class="item" @click="$router.push('/plan-page?index=0')">
              <div>
                <!-- <p>审核体系</p> -->
                <p>体系审核</p>
                <!-- 体系落地检查 -->
                <p>计划设置、审核发布</p>
              </div>
              <img @click="openWindow" src="../../assets/images/big1.png" />
            </div>
            <div class="item" @click="$router.push('/site-page?index=1')">
              <div>
                <!-- <p>日常检查工作</p> -->
                <p>日常检查</p>
                <p>检查区域、审核发布</p>
                <!-- 现场检查 -->
              </div>
              <img @click="openWindow" src="../../assets/images/big2.png" />
            </div>
            <div class="item" @click="$router.push('/file-learn?index=4')">
              <div>
                <p>学习中心</p>
                <p>日常练习、组织业务测评</p>
              </div>
              <img @click="openWindow" src="../../assets/images/big3.png" />
            </div>
          </div>
        </div>
        <!-- <div @click="$router.push('/rules-page?index=2')">
              <div>
                <img src="../../assets/images/item3.png" />
                <span>考核细则</span>
              </div>
              <p>安全考核、客服考核</p>
            </div>
            <div @click="$router.push('/control-page?index=3')">
              <div>
                <img src="../../assets/images/item4.png" />
                <span>控制中心</span>
              </div>
              <p>最高权限审核组</p>
            </div> -->

        <!-- <div @click="$router.push('/follow-examine?index=5')">
              <div>
                <img src="../../assets/images/item6.png" />
                <span>不符合跟踪</span>
              </div>
              <p>所有不符合项的跟踪审核</p>
            </div> -->

        <!-- <div>
          <div @click="$router.push('/AnnualAuditPlan')">
            <div>
              <img src="../../assets/images/supplier.png" />
              <span>供应商审核</span>
            </div>
            <p>供应商与VDA6.3审核</p>
          </div>
          <div @click="$router.push('/historyPage')">
            <div>
              <img src="../../assets/images/assess.png" />
              <span>企业自评</span>
            </div>
            <p>企业自评自测</p>
          </div>
         </div> -->

        <!-- <div>
          <div
            @click="
              $router.push({
                path: '/Person_Setting',
                query: { path: './system-View' },
              })
            "
          >
            <div>
              <img src="../../assets/images/item7.png" />
              <span>人员设置</span>
            </div>
            <p>组织架构、新增人员</p>
          </div>
          <div
            @click="
              $router.push({
                path: '/role-permissions',
                query: { path: './system-View' },
              })
            "
          >
            <div>
              <img src="../../assets/images/item8.png" />
              <span>权限设置</span>
            </div>
            <p>角色设置、权限设置</p>
          </div>
          <div
            @click="
              $router.push({
                path: '/standard-import',
                query: { path: './question-bank-view' },
              })
            "
          >
            <div>
              <img src="../../assets/images/item9.png" />
              <span>题库维护</span>
            </div>
            <p>计划设置、审核发布</p>
          </div>
          <div>
            <div>
              <img src="../../assets/images/item10.png" />
              <span>公告</span>
            </div>
            <p>更多重要消息通知</p>
          </div>
         </div> -->
        <div class="other">
          <p>其他</p>
          <div class="otherItem">
            <div @click="$router.push('/follow-examine?index=5')">
              <img src="../../assets/images/other1.png" alt="" />
              <div>
                <p>问题关闭</p>
                <p>所有不符合项的跟踪审核</p>
              </div>
            </div>
            <div @click="$router.push('/historyPage')">
              <img src="../../assets/images/other2.png" alt="" />
              <div>
                <p>自评</p>
                <p>企业自评自测</p>
              </div>
            </div>
            <div
              @click="
                $router.push({
                  path: '/standard-import',
                  query: { path: './question-bank-view' },
                })
              "
            >
              <img src="../../assets/images/other3.png" alt="" />
              <div>
                <p>题库管理</p>
                <p>题库的各类增删改查</p>
              </div>
            </div>
          </div>
          <div class="otherItem">
            <div>
              <img src="../../assets/images/other4.png" alt="" />
              <div>
                <p>专项检查</p>
                <p>计划设置、审核发布计划设置、审核发布</p>
              </div>
            </div>
            <div>
              <img src="../../assets/images/other5.png" alt="" />
              <div>
                <p>公告</p>
                <p>更多重要消息通知</p>
              </div>
            </div>
            <div
              @click="
                $router.push({
                  path: '/quarter',
                  query: { path: './statistic-view' },
                })
              "
            >
              <img src="../../assets/images/other6.png" alt="" />
              <div>
                <p>统计分析</p>
                <p>计划设置、审核发布计划设置、审核发布</p>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      :title="showDialogInd == 1 ? '加入公司' : '创建团队'"
      :visible.sync="showDialog"
      width="40%"
      :top="showDialogInd == 1 ? '22vh' : '15vh'"
      class="add-dialog"
    >
      <div v-if="showDialogInd == 1" style="padding: 30px 0 50px 80px">
        <span>加入公司：</span>
        <el-select
          v-model="compyValue"
          filterable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="remoteMethod"
          :loading="compyLoading"
        >
          <el-option
            v-for="item in compnyList"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
          ></el-option>
        </el-select>
        <!-- <el-select
          filterable
          clearable
          v-model="compyValue"
          placeholder="请选择"
          style="width: 300px"
        >
          <el-option
            v-for="item in compnyList"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
          ></el-option>
        </el-select>-->
      </div>
      <div v-if="showDialogInd == 2" style="padding: 0 80px 40px 50px">
        <el-form
          label-width="80px"
          :model="compnyFrom"
          :rules="rules"
          ref="ruleForm"
          :show-message="false"
        >
          <el-form-item label="公司名称" prop="companyName">
            <el-input v-model="compnyFrom.companyName"></el-input>
          </el-form-item>
          <el-form-item label="公司编号">
            <el-input v-model="compnyFrom.companyNo"></el-input>
          </el-form-item>
          <el-form-item label="公司电话">
            <el-input v-model="compnyFrom.companyPhone"></el-input>
          </el-form-item>
          <el-form-item label="省市区">
            <v-distpicker
              @selected="handleAddress"
              :placeholders="placeholders"
            ></v-distpicker>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-input
              type="textarea"
              v-model="compnyFrom.companyDetailAddr"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-top: 10px; text-align: end">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="getTenantIdForAdd">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as SignalR from "@aspnet/signalr";
import Header from "../../components/public/Header.vue";
import { get, post } from "../../api/http";
import VDistpicker from "v-distpicker";
// import WebSocketClient from '../../modules/socket'

export default {
  components: {
    Header,
    VDistpicker,
  },
  data() {
    return {
      elementHeight: 0,
      showDialog: false,
      showDialogInd: 0,
      compnyFrom: {},
      compyValue: "",
      compnyList: [],
      connection: null,
      compnyName: "",
      deptName: "",
      user: {
        userNo: this.$store.state.userInfo.userNo,
        userName: this.$store.state.userInfo.userName,
      },
      message: {
        SendUserName: this.$store.state.userInfo.userName, //发送者名称
        ReceiveUserNo: "admin", //接收者编号
        ReceiveName: "admin", //接收者名称
        MessageContent: "发送的消息内容", //消息内容
      },
      dot: false,
      placeholders: {
        province: "------- 省 --------",
        city: "--- 市 ---",
        area: "--- 区 ---",
      },
      loading: false,
      compyLoading: false,
      list: [],
      pendingForm: {},
      rules: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
      },
      imageUrl: "",
      defaultImg:require("../../assets/images/img1/cname.png")
    };
  },
  created() {
    // this.init();
    // const websocket = new WebSocketClient('wss://web.yuntixi.cn/chathub')
    // websocket.connect()
    // websocket.websocket.onmessage = (event) => {
    //   console.log('收到消息：', event.data)
    // }
    this.getCompanyList();
    this.getStudyAndExamNum();
    get(
      "/api/UserImage?UserId=" +
        JSON.parse(window.sessionStorage.getItem("userInfo")).id
    ).then((res) => {
      if (res.value != null) {
        this.imageUrl = res.value.imagesAddr ? res.value.imagesAddr : this.defaultImg;
      }
    });
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return new Date(e).toLocaleDateString();
    },
  },
  mounted() {
    if (this.$store.state.tenantId == 0) {
      // this.getOrganize();
    } else {
      this.getCompnyInfo();
    }
    this.getElementHeight();
  },
  // methods: {
  //   sendMessage() {
  //     websocket.send('hello')
  //   }
  // },
  // beforeDestroy() {
  //   websocket.close()
  // },
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
    loginUp() {
      window.sessionStorage.clear();
      sessionStorage.clear();
      window.location.reload();
      this.$message({
        type: "success",
        message: "退出成功",
      });
    },
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight - (document.getElementById("body").offsetTop + 5);
      });
    },
    rowDbClick(row) {
      row.planBeginDate = new Date(row.planBeginDate).toLocaleDateString();
      row.planEndDate = new Date(row.planEndDate).toLocaleDateString();
      if (row.approvalName) {
        this.$router.push({
          path: "/file-page?planId=" + row.id + "&time=" + row.planYear,
          query: {
            compId: row.compId,
            planBeginDate: row.planBeginDate,
            planEndDate: row.planEndDate,
            isProcess: row.isProcess,
          },
        });
      } else {
        this.$message("未批准计划不能跳转页面");
      }
    },
    rowDbClick1(e) {
      this.$router.push(
        "/site-list?Id=" +
          e.id +
          "&auditAreaNo=" +
          e.auditAreaNo +
          "&upCompanyId=" +
          e.upCompanyId +
          "&index=" +
          this.curindex +
          "&AreaId=" +
          e.auditAreaId
      );
    },
    rowDbClick2(e) {
      this.$router.push(
        "./rules-list?Id=" +
          e.id +
          "&CpyID=" +
          e.companyId +
          "&typeBtnIndex=" +
          "安全考核" +
          "&status=" +
          e.status
      );
    },
    toLearn() {
      sessionStorage.setItem("isSpecial", 0);
      sessionStorage.setItem("isLean", 1);
      this.$router.push("/assignStudy");
    },
    toTheme() {
      sessionStorage.setItem("isSpecial", 1);
      sessionStorage.setItem("isLean", 0);
      this.$router.push("/assignStudy");
    },
    // 获取待学习，待考试数量
    getStudyAndExamNum() {
      get("/api/HomePageData/GetAssignByUser").then((res) => {
        if (res.code == 200) {
          this.pendingForm = res.data;
        }
      });
    },
    // 加入公司 远程搜索
    remoteMethod(query) {
      if (query !== "") {
        this.compyLoading = true;
        setTimeout(() => {
          this.compyLoading = false;
          get("/api/Company/GetOrganize").then((res) => {
            if (res.code == 200) {
              this.list = res.data;
              this.compnyList = this.list.filter((item) => {
                return (
                  item.companyName.toLowerCase().indexOf(query.toLowerCase()) >
                  -1
                );
              });
            }
          });
        }, 200);
      } else {
        this.compnyList = [];
      }
    },
    init() {
      if (this.connection === null) {
        this.connection = new SignalR.HubConnectionBuilder()
          .withUrl("https://web.yuntixi.cn/chathub")
          .build();
        sessionStorage.setItem("connection", JSON.stringify(this.connection));
        this.connection.on("receiveHello", (res) => {
          console.log(res);
          sessionStorage.setItem("RecID", res);
        });
        this.connection.on("sendData", (res) => {
          console.log(res);
          if (res.length > 0) {
            this.dot = true;
          }
          // this.count = res.length;
          // sessionStorage.setItem("RecItem", JSON.stringify(res));
        });
        this.connection.on("SendHello", (res) => {
          console.log(res);
        });
        this.connection
          .start()
          .catch(() => {})
          .then(() => {
            this.connectionHub();
          });
      }
    },
    connectionHub() {
      if (this.connection != null) {
        this.connection
          .invoke("SendHello", this.user.userNo, this.user.userName)
          .then((res) => {
            console.log(res);
          })
          .catch((res) => {
            console.log(res);
            console.log("连接失败");
          });
      }
    },
    clickBtn() {
      this.connection.invoke("SendMsg", JSON.stringify(this.message));
    },
    // 创建/加入团队
    // getOrganize() {
    //   get("/api/Company/GetOrganize").then((res) => {
    //     if (res.code == 200) {
    //       this.compnyList = res.data;
    //     }
    //   });
    // },
    handleClose() {
      this.showDialog = false;
      this.showDialogInd = null;
      this.$router.push("./home");
    },
    clickShowDialog(e) {
      this.showDialog = true;
      this.showDialogInd = e;
    },
    handleAddress(e) {
      this.compnyFrom.companyAddress1 = e.province.value;
      this.compnyFrom.companyAddress2 = e.city.value;
      this.compnyFrom.companyAddress3 = e.area.value;
    },
    getTenantIdForAdd() {
      var reqHeader = function () {};
      if (this.showDialogInd == 1) {
        if (this.compyValue) {
          reqHeader = post(
            "/api/Company/AddOrganize?CompId=" + this.compyValue
          );
        } else {
          this.$message({
            type: "warning",
            message: "请输入要加入的公司",
          });
          return;
        }
      } else {
        if (!this.compnyFrom.companyAddress3) {
          this.$message({
            type: "warning",
            message: "请选择省市区",
          });
          return;
        }
        this.compnyFrom.saveTime = new Date().toISOString();
        this.compnyFrom.saveUserId = this.$store.state.userInfo.id + "";
        reqHeader = post("/api/Company/CreateOrganize", this.compnyFrom);
      }
      reqHeader
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message:
                this.showDialogInd == 1 ? "加入公司成功" : "创建团队成功",
            });
            this.showDialog = false;
            this.getLogin();
            if (this.showDialogInd != 1) {
              this.$confirm("是否导入数据?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.loading = true;
                  post(
                    "/api/TestDataImport?CompId=" +
                      this.$store.state.userInfo.upCompanyId
                  ).then((res) => {
                    if (res.code == 200) {
                      this.loading = false;
                      this.$message({
                        type: "success",
                        message: "导入成功!",
                      });
                    } else {
                      this.loading = false;
                      this.$message.error(res.message);
                    }
                  });
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消导入",
                  });
                });
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$message.error(
            this.showDialogInd == 1 ? "加入公司失败" : "创建团队失败"
          );
        });
    },
    getLogin() {
      var userInfo = this.$store.state.userInfo;
      get(
        "/api/DoLogin/DoNoPwdMyLogin?UserNo=" +
          userInfo.userNo +
          "&UserName=" +
          userInfo.userName
      ).then((resp) => {
        this.setData(resp);
      });
    },
    setData(resp) {
      sessionStorage.setItem("userInfo", JSON.stringify(resp.data));
      this.$store.dispatch("userInfo", resp.data);
      sessionStorage.setItem("token", JSON.stringify(resp.data.userToken));
      sessionStorage.setItem("tenantId", JSON.stringify(resp.data.tenantId));
      this.$store.dispatch("tenantId", resp.data.tenantId);
      this.getCompanyList();
    },
    async getCompanyList() {
      await get("/api/Company/GetAllByUser").then((resp) => {
        this.$store.dispatch("companyUserList", resp.data);
        sessionStorage.setItem("companyUserList", JSON.stringify(resp.data));
      });
      await get("/api/Company/GetAll").then((resp) => {
        if (resp.code == 200) {
          this.$store.dispatch("companyList", resp.data);
          sessionStorage.setItem("companyList", JSON.stringify(resp.data));
        }
      });
      this.getCompnyInfo();
    },
    getCompnyInfo() {
      if (this.$store.state.companyList) {
        this.$store.state.companyList.map((i) => {
          if (i.id == this.$store.state.userInfo.upCompanyId) {
            this.compnyName = i.companyName;
          }
        });
      }
      get(
        "/api/Department/GetNoTree?CompId=" +
          this.$store.state.userInfo.upCompanyId
      ).then((resp) => {
        if (resp.code == 200) {
          resp.data.forEach((e) => {
            if (e.id == this.$store.state.userInfo.upDeptmentId) {
              this.deptName = e.deptmentName;
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.rightIcon {
  position: absolute;
  right: 20px;
  color: silver;
}
.fontSize14 {
  font-size: 14px;
  font-weight: bold;
}
.img18 {
  width: 16px;
  margin-right: 5px;
}

// 左边
.aside {
  width: 350px !important;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  > div {
    background-color: white;
    padding: 10px 20px;
  }
  .compy {
    position: relative;
    display: flex;
    flex: 0;
    align-items: center;
    margin-bottom: 10px;
    div {
      padding-left: 10px;
      p:nth-of-type(1) {
        font-weight: bold;
      }
      p {
        margin-top: 5px;
      }
    }
    span {
      color: #929090;
    }
    img {
      border-radius: 50px;
      width: 28px;
    }
    .el-icon-arrow-right {
      .rightIcon;
    }
  }
  .user-info {
    flex: 0;
    margin-bottom: 10px;
    > div {
      display: flex;
      align-items: center;
    }
    .user {
      .fontSize14;
      img {
        width: 28px;
        height: 28px;
        border-radius: 50px;
        margin-right: 5px;
      }
    }
    .position {
      border-bottom: 1px solid #c0c0c03a;
      justify-content: space-between;
      margin-top: 5px;
      padding-bottom: 10px;
      span:nth-of-type(1) {
        color: #929090;
      }
    }
  }
  .title {
    .fontSize14;
    margin: 10px 0;
    img {
      .img18;
    }
  }
  .item {
    margin-top: 5px;
    span {
      width: 300px;
    }
  }
  .item1 {
    color: #929090;
  }
  .item2 {
    font-weight: bold;
  }
  .bulletin {
    flex: 1;
    position: relative;
    .title {
      display: flex;
      align-items: center;
    }
    .el-icon-arrow-right {
      top: 20px;
      .rightIcon;
    }
    .info {
      padding-top: 3px;
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
// 右边
.main {
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  .use {
    > p {
      font-size: 18px;
      border-left: 3px solid #3f87ff;
      padding-left: 5px;
      line-height: 18px;
      margin-top: 5px;
      margin-bottom: 20px;
    }
    .userItem {
      display: flex;
      flex-wrap: wrap;
      border-top: 5px solid white;
      padding-top: 30px;
      width: 100%;
      > div {
        border-bottom: 2px solid #eeeded;
        position: relative;
        display: flex;
        background: white;
        height: 130px;
        padding-left: 25px;
        margin-top: 30px;
        div {
          p:nth-of-type(1) {
            font-weight: bold;
            font-size: 16px;
            color: #2d4364;
          }
          p:nth-of-type(2) {
            color: #98a9bf;
            margin-top: 15px;
          }
        }
      }
      .png {
        margin-right: 5px;
        flex: 0.5;
        display: flex;
        padding-right: 20px;
        background-image: url("../../assets/images/big4.png");
        background-size: 100% 100%;
        img {
          width: 23px;
          position: absolute;
          margin-top: 5px;
          padding-left: 2px;
        }
        p:nth-of-type(1) {
          margin-top: 20px;
        }
        p:nth-of-type(2) {
          margin-top: 5px !important;
        }
      }
      .item {
        margin-right: 15px;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        img {
          position: relative;
          width: 45%;
        }
      }
    }
  }
  .other {
    margin-top: 25px;
    margin-right: 15px;
    background: white;
    > p {
      font-size: 18px;
      padding-left: 15px;
      line-height: 18px;
      margin-top: 15px;
    }
    .otherItem {
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;
      > div {
        flex: 1;
        display: flex;
        align-items: center;
        padding: 35px 0;
        justify-content: center;
        img {
          width: 50px;
          margin-right: 10px;
        }
        > div {
          width: 250px;
          p:nth-of-type(1) {
            font-size: 16px;
          }
          p:nth-of-type(2) {
            color: #98a9bf;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
/deep/ .add-dialog .el-form-item {
  margin-bottom: 0 !important;
}
/deep/ .distpicker-address-wrapper select {
  height: 30px !important;
  font-size: 10px !important;
  border-radius: 0 !important;
  outline: none;
  padding: 0 !important;
}
</style>